import ApiService from "@/core/services/api/v2/api.service";

const VEHICLE_TYPE_LIST_KEY = "VehicleTypeList";
const VEHICLE_TYPE_BASE_KEY = "VehicleTypeBase";

const VehicleTypeService = {
  getProfile(vehicleTypeID) {
    return new Promise(resolve => {
      ApiService.get("vehicleType/" + vehicleTypeID)
        .then(response => {
          resolve(response.data.response);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  updateProfile(vehicleTypeID, newData) {
    return new Promise(resolve => {
      ApiService.put("vehicleType/" + vehicleTypeID, newData)
        .then(response => {
          this.cleanCachedList();
          resolve(response.status);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  deleteProfile(vehicleTypeID) {
    return new Promise(resolve => {
      ApiService.delete("vehicleType/" + vehicleTypeID)
        .then(response => {
          this.cleanCachedList();
          resolve(response.status);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  createProfile(newData) {
    return new Promise(resolve => {
      ApiService.put("vehicleType", newData)
        .then(response => {
          this.cleanCachedList();
          // Response (if correct) holds the new VehicleTypeID
          resolve(response.data.response);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  search(searchText, orderBy, orderDesc, limit, offset) {
    return new Promise(resolve => {
      // Parse params
      const queryParams = {};

      searchText = searchText.trim();
      if (searchText.length > 0) {
        queryParams.Search = searchText;
      }

      orderBy = orderBy ? orderBy.trim() : "";
      if (orderBy.length > 0) {
        queryParams.OrderBy = orderBy;
      }

      queryParams.OrderDesc = orderDesc ? 1 : 0;

      limit = parseInt(limit);
      if (!isNaN(limit) && limit > 0) {
        queryParams.Limit = limit;
      }

      offset = parseInt(offset);
      if (!isNaN(offset)) {
        queryParams.Offset = offset;
      }

      ApiService.query("vehicleType", { params: queryParams })
        .then(response => {
          // Return full response, not just "VehicleTypes" array, for pagination etc
          resolve(response.data);
        })
        .catch(() => {
          resolve(404);
        });
    });
  },

  listAll() {
    return new Promise(resolve => {
      // This list is widely used and changes rarely, so cache its values
      let cachedList = window.sessionStorage.getItem(VEHICLE_TYPE_LIST_KEY);
      if (cachedList) {
        resolve(JSON.parse(cachedList));
      } else {
        ApiService.get("vehicleType")
          .then(response => {
            let newList = response.data.response.VehicleTypes;

            window.sessionStorage.setItem(
              VEHICLE_TYPE_LIST_KEY,
              JSON.stringify(newList)
            );

            resolve(newList);
          })
          .catch(() => {
            resolve([]);
          });
      }
    });
  },

  getTypeBase() {
    return new Promise(resolve => {
      // This name is widely used and changes rarely, so cache its value
      let cachedBase = window.sessionStorage.getItem(VEHICLE_TYPE_BASE_KEY);

      if (cachedBase) {
        resolve(JSON.parse(cachedBase));
      } else {
        ApiService.get("vehicleTypeBase")
          .then(response => {
            let newBase = response.data.response;
            window.sessionStorage.setItem(
              VEHICLE_TYPE_BASE_KEY,
              JSON.stringify(newBase)
            );
            resolve(newBase);
          })
          .catch(() => {
            resolve(null);
          });
      }
    });
  },

  cleanCachedList() {
    window.sessionStorage.removeItem(VEHICLE_TYPE_LIST_KEY);
    window.sessionStorage.removeItem(VEHICLE_TYPE_BASE_KEY);
  }
};

export default VehicleTypeService;
