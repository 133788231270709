<template>
  <!-- begin::Trucks edit -->
  <div>
    <div class="manager-title">
      <inline-svg
        class="manager-title-icon"
        src="/media/icons/truck-primary.svg"
      />
      <div>
        {{
          TruckID > 0
            ? $t("FLEET.TRUCKS.TITLE_EDIT")
            : $t("FLEET.TRUCKS.TITLE_ADD")
        }}
        <hr class="manager-title-sub" />
      </div>
    </div>

    <div class="white-box shadow-box">
      <v-form ref="formBasic" v-model="Valid" lazy-validation class="form">
        <div class="manager-subtitle">
          {{ $t("FLEET.TRUCKS.SUBTITLE") }}
        </div>

        <div class="row">
          <div class="form-group col-lg-4">
            <label>
              {{ $t("FLEET.TRUCKS.NICK") }}
            </label>
            <v-text-field
              class="form-control"
              v-model="Fields.Nick"
              :placeholder="$t('FLEET.TRUCKS.NICK')"
              required
              :rules="[rules.required]"
            />
          </div>

          <div class="form-group col-lg-4">
            <label>
              {{ $t("FLEET.TRUCKS.PLATE") }}
            </label>
            <v-text-field
              class="form-control"
              v-model="Fields.Plate"
              :placeholder="$t('FLEET.TRUCKS.PLATE')"
              required
              :rules="[rules.required]"
            />
          </div>
        </div>

        <div class="row">
          <div class="form-group col-lg-4">
            <label>
              {{ $t("FLEET.CATEGORIES.CATEGORY") }}
            </label>
            <select
              class="form-control select-down"
              v-model="Fields.TruckCategoryID"
              required
              :rules="[rules.required]"
            >
              <option
                v-for="tc in TruckCategoryOptions"
                :key="tc.TruckCategoryID"
                :value="tc.TruckCategoryID"
                class="text-primary"
              >
                {{ getTruckCategoryName(tc.TruckCategoryID) }}
              </option>
            </select>
          </div>

          <div class="col-lg-8 d-flex align-items-center">
            <inline-svg
              class="svg-icon"
              src="/media/icons/help-alt-5-primary.svg"
            />
            <span
              class="pl-3"
              v-html="
                $t('FLEET.CATEGORIES.CATEGORY_INFO', {
                  typeBasePosition: VehicleTypeBase.Position,
                  typeBaseName: VehicleTypeBase.Name
                })
              "
            />
          </div>

          <div class="col-lg-4">
            <router-link
              to="/manager/trucks/categories/new"
              v-slot="{ navigate }"
              custom
            >
              <button
                class="btn btn-pill button-filter btn-primary"
                @click="navigate"
              >
                <inline-svg src="/media/icons/add.svg" class="mr-3" />
                {{ $t("FLEET.CATEGORIES.TITLE_ADD") }}
              </button>
            </router-link>
          </div>
        </div>
      </v-form>

      <div class="row button-row">
        <button
          class="btn btn-pill btn-white button-form button-form-primary mr-9"
          @click="$router.push('/manager/trucks')"
        >
          <inline-svg src="/media/icons/arrow-return-primary.svg" class="mr-3" />
          {{ $t("GENERIC.BACK") }}
        </button>

        <button
          class="btn btn-pill btn-tertiary button-form"
          :disabled="!Valid"
          @click="onSubmitForm"
        >
          {{ $t("GENERIC.SAVE") }}
        </button>
      </div>
    </div>
  </div>
  <!-- end::Trucks edit -->
</template>

<style scoped>
.svg-icon {
  width: 1.5em !important;
  height: 1.5em !important;
  min-width: 1.5em !important;
  min-height: 1.5em !important;
}
</style>

<script>
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from "@/core/services/store/htmlclass.module.js";

import TruckCategoryService from "@/core/services/api/v2/truckcategory.service";
import TruckService from "@/core/services/api/v2/truck.service";
import VehicleTypeService from "@/core/services/api/v2/vehicletype.service";

export default {
  name: "TrucksEdit",
  data() {
    return {
      TruckID: 0,
      TruckCategoryOptions: [],
      VehicleTypeBase: {
        Position: "",
        Name: ""
      },
      Valid: true,
      Fields: {
        Nick: "",
        Plate: "",
        TruckCategoryID: null
      },
      rules: {
        required: value => !!value || this.$i18n.t("VALIDATION.REQUIRED_FIELD")
      }
    };
  },

  mounted() {
    this.loadVehicleTypeBase();
  },

  methods: {
    loadVehicleTypeBase() {
      VehicleTypeService.getTypeBase().then(response => {
        if (response) {
          this.VehicleTypeBase = response;
        } else {
          this.VehicleTypeBase.Position = "";
          this.VehicleTypeBase.Name = "?";
        }

        this.loadTruckCategoryOptions();
      });
    },

    loadTruckCategoryOptions() {
      // Show page loader
      this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");

      this.TruckCategoryOptions = [];
      TruckCategoryService.listAll().then(response => {
        this.TruckCategoryOptions = response;

        // Edit (a certain "id") or create new?
        if (this.$route.params.id) {
          this.TruckID = parseInt(this.$route.params.id);
          this.loadTruckData();
        } else {
          this.TruckID = -1;

          // Remove page loader
          this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
        }
      });
    },

    loadTruckData() {
      TruckService.getProfile(this.TruckID).then(response => {
        if (response === 404) {
          this.$router.push("/manager/fleet");
        } else {
          for (var f1 in this.Fields) {
            this.Fields[f1] = response[f1];
          }

          // Remove page loader
          this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
        }
      });
    },

    getTruckCategoryName(tcID) {
      let name = " - ";

      for (let t = 0; t < this.TruckCategoryOptions.length; t++) {
        let maybeTC = this.TruckCategoryOptions[t];
        if (maybeTC.TruckCategoryID === tcID) {
          name = maybeTC.Capacity;
          if (maybeTC.IsDefault) {
            name += " (" + this.$i18n.t("FLEET.CATEGORIES.DEFAULT") + ")";
          }

          break;
        }
      }

      return name;
    },

    onSubmitForm(e) {
      e.preventDefault();

      if (this.$refs.formBasic.validate()) {
        // Disable submit button
        this.Valid = false;

        // Send request:
        if (this.TruckID > 0) {
          // Update Truck
          TruckService.updateProfile(this.TruckID, this.Fields).then(
            statusCode => {
              if (statusCode >= 400) {
                let whyError = this.$i18n.t("GENERIC.TRY_LATER");
                if (statusCode === 409) {
                  whyError = this.$i18n.t("FLEET.TRUCKS.ERROR_409");
                } else if (statusCode === 412) {
                  whyError = this.$i18n.t("FLEET.TRUCKS.ERROR_412");
                } else if (statusCode === 400) {
                  whyError = this.$i18n.t("VALIDATION.MISSING_REQUIRED_FIELDS");
                }

                this.$bvToast.toast(whyError, {
                  title: this.$i18n.t("GENERIC.SAVE_ERROR"),
                  variant: "danger",
                  solid: true
                });
              } else {
                this.$bvToast.toast(" ", {
                  title: this.$i18n.t("GENERIC.SAVE_OK"),
                  variant: "success",
                  solid: true
                });
              }

              // Reset submit button
              this.Valid = true;
            }
          );
        } else {
          // Create new Truck: we get back its ID
          TruckService.createProfile(this.Fields).then(response => {
            if (response.TruckID) {
              this.$bvToast.toast(" ", {
                title: this.$i18n.t("GENERIC.SAVE_OK"),
                variant: "success",
                solid: true
              });

              this.TruckID = response.TruckID;
              setTimeout(
                () => this.$router.push("/manager/trucks/edit/" + this.TruckID),
                2000
              );
            } else {
              let whyError = this.$i18n.t("GENERIC.TRY_LATER");
              if (response === 409) {
                whyError = this.$i18n.t("FLEET.TRUCKS.ERROR_409");
              } else if (response === 412) {
                whyError = this.$i18n.t("FLEET.TRUCKS.ERROR_412");
              } else if (response === 400) {
                whyError = this.$i18n.t("VALIDATION.MISSING_REQUIRED_FIELDS");
              }

              this.$bvToast.toast(whyError, {
                title: this.$i18n.t("GENERIC.SAVE_ERROR"),
                variant: "danger",
                solid: true
              });
            }

            // Reset submit button
            this.Valid = true;
          });
        }
      }
    }
  }
};
</script>
