import ApiService from "@/core/services/api/v2/api.service";

const TRUCK_CATEGORY_LIST_KEY = "TruckCategoryList";

const TruckCategoryService = {
  getProfile(truckCategoryID) {
    return new Promise(resolve => {
      ApiService.get("truckCategory/" + truckCategoryID)
        .then(response => {
          resolve(response.data.response);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  updateProfile(truckCategoryID, newData) {
    return new Promise(resolve => {
      ApiService.put("truckCategory/" + truckCategoryID, newData)
        .then(response => {
          this.cleanCachedList();
          resolve(response.status);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  deleteProfile(truckCategoryID) {
    return new Promise(resolve => {
      ApiService.delete("truckCategory/" + truckCategoryID)
        .then(response => {
          this.cleanCachedList();
          resolve(response.status);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  createProfile(newData) {
    return new Promise(resolve => {
      ApiService.put("truckCategory", newData)
        .then(response => {
          this.cleanCachedList();
          // Response (if correct) holds the new TruckCategoryID
          resolve(response.data.response);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  search(searchText, orderBy, orderDesc, limit, offset) {
    return new Promise(resolve => {
      // Parse params
      const queryParams = {};

      searchText = searchText.trim();
      if (searchText.length > 0) {
        queryParams.Search = searchText;
      }

      orderBy = orderBy ? orderBy.trim() : "";
      if (orderBy.length > 0) {
        queryParams.OrderBy = orderBy;
      }

      queryParams.OrderDesc = orderDesc ? 1 : 0;

      limit = parseInt(limit);
      if (!isNaN(limit) && limit > 0) {
        queryParams.Limit = limit;
      }

      offset = parseInt(offset);
      if (!isNaN(offset)) {
        queryParams.Offset = offset;
      }

      ApiService.query("truckCategory", { params: queryParams })
        .then(response => {
          // Return full response, not just "TruckCategories" array, for pagination etc
          resolve(response.data);
        })
        .catch(() => {
          resolve(404);
        });
    });
  },

  listAll() {
    return new Promise(resolve => {
      // This list is widely used and changes rarely, so cache its values
      let cachedList = window.sessionStorage.getItem(TRUCK_CATEGORY_LIST_KEY);
      if (cachedList) {
        resolve(JSON.parse(cachedList));
      } else {
        ApiService.query("truckCategory", { params: { Limit: 999 } })
          .then(response => {
            let newList = response.data.response.TruckCategories;

            window.sessionStorage.setItem(
              TRUCK_CATEGORY_LIST_KEY,
              JSON.stringify(newList)
            );

            resolve(newList);
          })
          .catch(() => {
            resolve([]);
          });
      }
    });
  },

  cleanCachedList() {
    window.sessionStorage.removeItem(TRUCK_CATEGORY_LIST_KEY);
  }
};

export default TruckCategoryService;
