import ApiService from "@/core/services/api/v2/api.service";

const TRUCK_LIST_KEY = "TruckList";
const TRUCK_FILTER_KEY = "TruckFilter";

const TruckService = {
  getProfile(truckID) {
    return new Promise(resolve => {
      ApiService.get("truck/" + truckID)
        .then(response => {
          resolve(response.data.response);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  updateProfile(truckID, newData) {
    return new Promise(resolve => {
      ApiService.put("truck/" + truckID, newData)
        .then(response => {
          this.cleanCachedList();
          resolve(response.status);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  deleteProfile(truckID) {
    return new Promise(resolve => {
      ApiService.delete("truck/" + truckID)
        .then(response => {
          this.cleanCachedList();
          resolve(response.status);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  createProfile(newData) {
    return new Promise(resolve => {
      ApiService.post("truck", newData)
        .then(response => {
          this.cleanCachedList();
          // Response (if correct) holds the new TruckID
          resolve(response.data.response);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  search(
    searchText,
    filterByTruckCategoryID,
    orderBy,
    orderDesc,
    limit,
    offset
  ) {
    return new Promise(resolve => {
      // Parse params - storing a cache of the filters applied
      const queryParams = {};

      searchText = searchText.trim();
      if (searchText.length > 0) {
        queryParams.Search = searchText;
      }

      filterByTruckCategoryID = parseInt(filterByTruckCategoryID);
      if (!isNaN(filterByTruckCategoryID) && filterByTruckCategoryID > 0) {
        queryParams.TruckCategoryID = filterByTruckCategoryID;
      }

      window.sessionStorage.setItem(
        TRUCK_FILTER_KEY,
        JSON.stringify(queryParams)
      );

      orderBy = orderBy ? orderBy.trim() : "";
      if (orderBy.length > 0) {
        queryParams.OrderBy = orderBy;
      }

      queryParams.OrderDesc = orderDesc ? 1 : 0;

      limit = parseInt(limit);
      if (!isNaN(limit) && limit > 0) {
        queryParams.Limit = limit;
      }

      offset = parseInt(offset);
      if (!isNaN(offset)) {
        queryParams.Offset = offset;
      }

      // Do search
      ApiService.query("truck", { params: queryParams })
        .then(response => {
          // Return full response, not just "Trucks" array, for pagination etc
          resolve(response.data);
        })
        .catch(() => {
          resolve(404);
        });
    });
  },

  getCachedFilters() {
    let cachedFilters = window.sessionStorage.getItem(TRUCK_FILTER_KEY);
    if (cachedFilters) {
      return JSON.parse(cachedFilters);
    } else {
      return {};
    }
  },

  listAll() {
    return new Promise(resolve => {
      // This list is widely used and changes rarely, so cache its values
      let cachedList = window.sessionStorage.getItem(TRUCK_LIST_KEY);
      if (cachedList) {
        resolve(JSON.parse(cachedList));
      } else {
        ApiService.query("truck", { params: { Limit: 999 } })
          .then(response => {
            let newList = response.data.response.Trucks;

            window.sessionStorage.setItem(
              TRUCK_LIST_KEY,
              JSON.stringify(newList)
            );

            resolve(newList);
          })
          .catch(() => {
            resolve([]);
          });
      }
    });
  },

  cleanCachedList() {
    window.sessionStorage.removeItem(TRUCK_LIST_KEY);
  }
};

export default TruckService;
